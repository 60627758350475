import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { Link, Stack, Text } from "@fluentui/react";
import { withSentryReactRouterV6Routing } from "@sentry/react";
import { Suspense, lazy } from "react";
import { Link as ReactRouterLink, Route, Routes } from "react-router";

import Spinner from "@/component/Spinner";
import Main from "@/page/Main";

import styles from "./App.module.css";

const ImprintLazy = lazy(() => import("@/page/Imprint"));
const PrivacyLazy = lazy(() => import("@/page/Privacy"));
// const ApiDocsLazy = lazy(() => import("@/page/ApiDocs"));

// Ref: https://developer.microsoft.com/en-us/fluentui#/styles/web/icons
// Needed for controls that use fluent's icons
initializeIcons();

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export default function App() {
	return (
		<div className={styles.app}>
			<header>
				<Link as={ReactRouterLink} to="">
					<Text variant="xLarge">brief.jetzt</Text>
				</Link>
			</header>
			<main>
				<Suspense fallback={<Spinner />}>
					<SentryRoutes>
						<Route path="" element={<Main />} />
						<Route path="imprint" element={<ImprintLazy />} />
						<Route path="privacy" element={<PrivacyLazy />} />
						{/*<Route path="api-docs" element={<ApiDocsLazy />} />*/}
					</SentryRoutes>
				</Suspense>
			</main>
			<footer>
				<Stack
					tokens={{
						childrenGap: 10,
						padding: 10,
					}}
					horizontalAlign="center"
					horizontal
					wrap
				>
					<Link as={ReactRouterLink} to="imprint">
						Impressum
					</Link>
					<Link as={ReactRouterLink} to="privacy">
						Datenschutzerklärung
					</Link>
					<Link as={ReactRouterLink} to="imprint">
						Kontakt
					</Link>
					{/*<Link as={ReactRouterLink} to="api-docs">*/}
					{/*	API-Dokumentation*/}
					{/*</Link>*/}
				</Stack>
				{/* the additional stack is needed because the feedback button is
				otherwise overlapping Impressum / Datenschutzerklärung / Kontakt on mobile */}
				<Stack
					tokens={{
						childrenGap: 10,
						padding: "20px 10px",
					}}
					horizontal
					wrap
				>
					<span style={{ color: "#aaa", fontSize: "8pt", height: "1em" }}>
						Version: {import.meta.env.VITE_RELEASE}
					</span>
				</Stack>
			</footer>
		</div>
	);
}
